import React from 'react';
import styles from './BreedContentList.module.sass';
import PodcastTile from 'src/components/pages/podcasts/PodcastTile/PodcastTile';

const BreedContentList = ({data}) => {
  const { articles, podcasts } = data;
  return (
    <div className={styles.listWrap}>

      {articles && articles.length > 0 && <h3 className={styles.sectionTitle}>Artykuły</h3>}
      {articles && articles.length > 0 && articles.map(podcast => (
        <PodcastTile key={podcast.id} podcast={podcast} article/>
      ))}

      {podcasts && podcasts.length > 0 && <h3 className={styles.sectionTitle}>Podcasty</h3>}
      {podcasts && podcasts.length > 0 && podcasts.map(podcast => (
        <PodcastTile key={podcast.id} podcast={podcast}/>
      ))}
    </div>
  );
};

export default BreedContentList;