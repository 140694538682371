import React from 'react';
import styles from './breeds.module.sass'
import { graphql } from 'gatsby';
import Layout from 'src/Theme/layout';
import SEO from 'src/components/seo';
import cs from 'classnames';
// import styles from 'src/pages/podcasty.module.sass';
import BigArticleTitle from 'src/components/shared/texts/BigArticleTitle/BigArticleTitle';
import BreedContentList from 'src/components/pages/podcasts/BreedContentList/BreedContentList';


export const query = graphql`
    query breedsData($id: String!) {
        allDatoCmsPodcast(filter: {breeds: {elemMatch: {breed: {breed: {eq: $id}}}}}) {
            nodes{
                __typename
                breeds{
                    breed{
                        breed
                    }
                }
                title
                miniature {
                  alt
                    fluid(maxWidth: 900) {
                        ...GatsbyDatoCmsFluid_tracedSVG
                    }
                }
                shortDescription
                id
                meta{
                    createdAt
                }
            }
        }
        allDatoCmsArticle(filter: {breeds: {elemMatch: {breed: {breed: {eq: $id}}}}}) {
            nodes{
                __typename
                breeds{
                    breed{
                        breed
                    }
                }
                title
                promotedOnFrontPage
                shortDescription
                id
                miniature {
                  alt
                    fluid(maxWidth: 900) {
                        ...GatsbyDatoCmsFluid_tracedSVG
                    }
                }
                meta{
                    createdAt
                }
            }
        }
    }
`;

const Breed = ({data, pageContext}) => {
  const { id: breed } = pageContext
  const { allDatoCmsArticle: { nodes: articles}, allDatoCmsPodcast: {nodes: podcasts}} = data;
  const allData = {articles, podcasts}
  return (
    <Layout>
      <SEO title={breed}/>
      <div className={cs(styles.podcastsWrap, 'firstSectionWrap')}>
        <BigArticleTitle className={styles.podcastsTitle}>{breed}</BigArticleTitle>
        <BreedContentList data={allData} />
      </div>
    </Layout>
  );
}

export default Breed;